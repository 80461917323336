import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MEDIA from 'utils/helpers/media'

const Paragraph = styled.p`
  font-size: ${(props) => props.theme.fontSizes.msmallplusbigger};
  font-weight: ${(props) =>
    props.weight ? props.weight : props.theme.fontWeights.light};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  color: ${(props) => {
    let color
    if (props.color === 'white') {
      color = props.theme.colors.white
    } else if (props.color === 'blue') {
      color = props.theme.colors.blue
    } else if (props.color === 'black') {
      color = props.theme.colors.black
    }
    return color
  }};
  word-wrap: ${(props) => (props.breakWord ? 'break-word' : '')};
  ${MEDIA.MIN_TABLET`
    font-size: ${(props) => props.theme.fontSizes.tsmallplusbigger};
    `}
`
const SmallText = ({ color, children, align, breakWord, weight }) => (
  <Paragraph color={color} weight={weight} align={align} breakWord={breakWord}>
    {children}
  </Paragraph>
)

SmallText.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
  weight: PropTypes.oneOf([300, 400, 500, 600, 800]),
  color: PropTypes.string,
  breakWord: PropTypes.bool,
}

SmallText.defaultProps = {
  align: 'left',
  color: 'black',
  weight: 500,
  breakWord: false,
}

export default SmallText
