import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import MarginWrapper from 'components/UI/MarginWrapper/MarginWrapper'
import translations from 'assets/translations/translations'
import gsap from 'gsap'
import MarginBottom from 'components/UI/MarginBottom/MarginBottom'
import Paragraph from 'components/Typography/Paragraphs/NormalText'
import InputButton from 'components/UI/Buttons/InputButton'
import SmallText from 'components/Typography/Paragraphs/SmallText'
import MEDIA from 'utils/helpers/media'
import LinkItem from 'components/Navbar/Links/LinkItem'

const BoxWidthContainer = styled.div``

const GridContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  ${MEDIA.MIN_DESKTOP`
    grid-auto-flow: column;
    grid-column-gap: 20px;
    `}
`

const Box = styled.div`
  background-color: ${(props) => props.theme.colors.blue};
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-bottom: 50px;
  ${MEDIA.MIN_DESKTOP`
    left: initial;
  right: initial;
  margin-left: initial;
  margin-right: initial;
  margin-bottom: 0;
  width: 100%;
  padding: 0 30px 0 30px;
    `}
`

const ButtonContainer = styled.div`
  display: grid;
  justify-content: end;
  margin: 20px 0 20px 0;
`

const InputWrapper = styled.div`
  position: relative;
`

const ErrorMessage = styled.div`
  bottom: -20px;
  font-size: ${(props) => props.theme.fontSizes.msmall};
  color: ${(props) => props.theme.colors.white};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  position: absolute;
  ${MEDIA.MIN_TABLET`
    font-size: ${(props) => props.theme.fontSizes.tsmall};
    bottom: -25px;
    `}
`

const Input = styled.input`
  color: ${(props) => props.theme.colors.white};
  width: 100%;
  height: 20px;
  outline: 0;
  border-width: 0 0 1px;
  border-color: ${(props) =>
    props.error ? props.theme.colors.red : props.theme.colors.white};
  background-color: transparent;

  &::placeholder {
    color: ${(props) => props.theme.colors.white};
    opacity: 0.5;
    font-size: ${(props) => props.theme.fontSizes.msmallplusbigger};
    font-weight: ${(props) => props.theme.fontWeights.light};
    padding: 5px;
  }
  &:focus {
    border-width: 0 0 2px;
  }
  ${MEDIA.MIN_TABLET`
    font-size: ${(props) => props.theme.fontSizes.tregular}
    height: 30px;
    border-width: 0 0 2px;
    &::placeholder {
    font-size: ${(props) => props.theme.fontSizes.mheadSmall};
    padding: 5px;
  }
    `}
`

const CompanyInformation = styled.div``

const InputsGrid = styled.div`
  display: grid;
  color: ${(props) => props.theme.colors.white};
  grid-auto-flow: row;
  grid-row-gap: 30px;
  ${MEDIA.MIN_TABLET`
  grid-row-gap: 50px;
    `}
`
const HeaderWrapper = styled.div`
  display: inline-flex;
  padding: 20px 0 20px 0;
  ${MEDIA.MIN_TABLET`
  padding: 30px 0 30px 0;
  
    `}
`

const HeaderBold = styled.div`
  font-size: ${(props) => props.theme.fontSizes.mregular};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  color: ${(props) => props.theme.colors.white};
  ${MEDIA.MIN_TABLET`
  font-size: ${(props) => props.theme.fontSizes.tregular};
    `}
`

const CompanyText = styled.div`
  font-size: ${(props) => props.theme.fontSizes.mheadSmall};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  ${MEDIA.MIN_TABLET`
    font-size: ${(props) => props.theme.fontSizes.theadSmall};
    `}
`

const Headquaters = styled.div`
  font-size: ${(props) => props.theme.fontSizes.msmallplusbigger};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  ${MEDIA.MIN_TABLET`
    font-size: ${(props) => props.theme.fontSizes.tsmallplusbigger};
    `}
`

const Adress = styled.div`
  font-size: ${(props) => props.theme.fontSizes.msmallplusbigger};
  font-weight: ${(props) => props.theme.fontWeights.light};
  white-space: pre-wrap;
  margin-bottom: 20px;
  ${MEDIA.MIN_TABLET`
    font-size: ${(props) => props.theme.fontSizes.tsmallplusbigger};
    `}
`
const FormSubmittedText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  justify-content: center;
  align-content: center;
  text-align: center;
  transform: translate(-50%, -50%);
  font-size: ${(props) => props.theme.fontSizes.mregular};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  color: ${(props) => props.theme.colors.white};
  opacity: 0;
  display: none;
`

const DescContainer = styled.div`
  color: ${(props) => props.theme.colors.white};
  text-align: justify;
  margin-bottom: 20px;
  ${MEDIA.MIN_TABLET`
  margin-bottom: 30px;
    `}
`

const Desc1 = styled.div`
  font-size: ${(props) => props.theme.fontSizes.msmall};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  ${MEDIA.MIN_TABLET`
  font-size: ${(props) => props.theme.fontSizes.tsmall};
    `}
`

const Desc2 = styled.div`
  font-size: ${(props) => props.theme.fontSizes.msmall};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  margin-right: 5px;
  cursor: pointer;
  &:hover {
    transform: scale(1.02);
  }
  ${MEDIA.MIN_TABLET`
  font-size: ${(props) => props.theme.fontSizes.tsmall};
    `}
`

const Form = () => {
  const { register, setError, clearErrors, handleSubmit, errors } = useForm()
  const formRef = React.useRef(null)
  const containerRef = React.useRef(null)
  const formSubmittedTextRef = React.useRef(null)
  const tl = React.useRef()
  const [isDisabled, setIsDisabled] = useState(false)

  React.useEffect(() => {
    tl.current = gsap.timeline({ paused: true })
    tl.current
      .to(containerRef.current, {
        opacity: 0,
        duration: 0.5,
        ease: 'power2.inOut',
        delay: 0.5,
      })
      .to(containerRef.current, {
        visibility: 'hidden',
      })
      .to(
        formSubmittedTextRef.current,
        {
          display: 'grid',
          opacity: 1,
          duration: 0.5,
        },
        '-=0.2',
      )
      .from(
        formSubmittedTextRef.current,
        {
          y: -50,
        },
        '-=0.5',
      )
    return () => {
      tl.current.kill()
    }
  }, [])

  const onSubmit = (data, e) => {
    setIsDisabled(true)
    e.preventDefault()
    fetch(`${process.env.GATSBY_BACKEND_URL_FORM}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(() => {
        setIsDisabled(false)
        formRef.current.reset()
        tl.current.play()
      })
      .catch(() => setIsDisabled(false))
  }

  const validateEmail = ({ target }) => {
    if (!target.value) {
      clearErrors('emailNotValid')
      return
    }
    // eslint-disable-next-line no-useless-escape
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (re.test(target.value)) {
      clearErrors('emailNotValid')
    } else {
      setError('emailNotValid', {
        type: 'manual',
      })
    }
  }

  const validatePhone = ({ target }) => {
    if (!target.value) {
      clearErrors('phoneNotValid')
      return
    }
    // eslint-disable-next-line no-useless-escape
    const re = /(-| |[0-9]|\+){9,15}/
    if (re.test(target.value)) {
      clearErrors('phoneNotValid')
    } else {
      setError('phoneNotValid', {
        type: 'manual',
      })
    }
  }

  return (
    <>
      <GridContainer>
        <Box>
          <div ref={containerRef}>
            <MarginWrapper>
              <BoxWidthContainer>
                <HeaderWrapper>
                  <HeaderBold>
                    {translations.headers.contact.contact}
                  </HeaderBold>
                  <div style={{ marginLeft: '5px' }}>
                    <Paragraph color="white">
                      {translations.headers.contact.us}
                    </Paragraph>
                  </div>
                </HeaderWrapper>
                <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                  <InputsGrid>
                    <InputWrapper>
                      <Input
                        error={errors.name}
                        name="name"
                        placeholder="Imię*"
                        ref={register({ required: true })}
                      />
                      {errors.name && (
                        <ErrorMessage>
                          {translations.errors.required}
                        </ErrorMessage>
                      )}
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        error={errors.surname}
                        name="surname"
                        placeholder="Nazwisko*"
                        ref={register({ required: true })}
                      />
                      {errors.surname && (
                        <ErrorMessage>
                          {translations.errors.required}
                        </ErrorMessage>
                      )}
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        name="company"
                        placeholder="Nazwa firmy"
                        ref={register({ required: false })}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        error={errors.phone || errors.phoneNotValid}
                        name="phone"
                        type="text"
                        onChange={validatePhone}
                        placeholder="Numer telefonu*"
                        ref={register({ required: true })}
                      />
                      {errors.phone && (
                        <ErrorMessage>
                          {translations.errors.required}
                        </ErrorMessage>
                      )}
                      {errors.phoneNotValid && (
                        <ErrorMessage>{translations.errors.phone}</ErrorMessage>
                      )}
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        name="email"
                        placeholder="Email*"
                        error={errors.email || errors.emailNotValid}
                        onChange={validateEmail}
                        ref={register({
                          required: true,
                        })}
                      />
                      {errors.email && (
                        <ErrorMessage>
                          {translations.errors.required}
                        </ErrorMessage>
                      )}
                      {errors.emailNotValid && (
                        <ErrorMessage>{translations.errors.email}</ErrorMessage>
                      )}
                    </InputWrapper>
                  </InputsGrid>
                  <ButtonContainer>
                    <InputButton isDisabled={isDisabled} />
                  </ButtonContainer>
                </form>
                <DescContainer>
                  <Desc1>
                    {translations.mainPage.sectionContact.form.ps1}
                    <div style={{ display: 'inline-block' }}>
                      <LinkItem pureLink link={translations.routes.privacy}>
                        <Desc2>
                          {translations.mainPage.sectionContact.form.ps2}
                        </Desc2>
                      </LinkItem>
                    </div>
                    {translations.mainPage.sectionContact.form.ps3}
                  </Desc1>
                </DescContainer>
              </BoxWidthContainer>
            </MarginWrapper>
          </div>
          <FormSubmittedText ref={formSubmittedTextRef}>
            {translations.UI.formSubmitted}
          </FormSubmittedText>
        </Box>
        <CompanyInformation>
          <CompanyText>{translations.company}</CompanyText>
          <Headquaters>{translations.headquaters}</Headquaters>
          <Adress>{translations.adress}</Adress>
          <SmallText weight={500} color="black">
            {translations.contactPage.openHours}
          </SmallText>
          <SmallText weight={300} color="black">
            {translations.contactPage.workingDays}
          </SmallText>
        </CompanyInformation>
      </GridContainer>
      <MarginBottom />
    </>
  )
}

export default Form
