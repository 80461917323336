import React from 'react'
import { useSelector } from 'react-redux'
import SEO from 'components/SEO/SEO'
import translations from 'assets/translations/translations'
import PageHeader from 'components/Typography/Headers/PageHeader'
import PageIntro from 'components/PageIntro/PageIntro'
import Form from 'components/Form/Form'
import Footer from 'components/Footer/Footer'

const Kontakt = () => {
  const location = useSelector(({ state }) => state.targetRoute)

  return (
    <>
      <SEO title={translations.pages.contact} pathname={location.pathname} />
      <PageIntro
        sectionLineHeader={translations.pages.contact}
        description={translations.descriptions.contact}
      >
        <PageHeader>{translations.headers.contact.begin}</PageHeader>
        <PageHeader blue>{translations.headers.contact.cooperation}</PageHeader>
      </PageIntro>
      <Form />
      <Footer />
    </>
  )
}

export default Kontakt
